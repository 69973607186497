<template>
    <div>
        <h2><i class="fa fa-shopping-bag"></i> LATEST PRODUCTS</h2>
        <div v-if="isLoading">Loading Ads...</div>
        <div>
            <AdvertisementItem
                v-if="surplusProducts.length"
                :item="surplusProducts[currentAdIdx]"
                :myProducts="myProducts"
            />
            <div class="d-flex">
                <button
                    style="min-width: 0"
                    class="primary-button"
                    @click="updateIndex(currentAdIdx - 1)"
                >
                    Prev
                </button>
                <button
                    style="min-width: 0"
                    class="primary-button"
                    @click="updateIndex(currentAdIdx + 1)"
                >
                    Next
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import request from "../../apis/request"
import AdvertisementItem from "./AdvertisementItem.vue"

export default {
    props: {
        myProducts: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        AdvertisementItem,
    },
    data() {
        return {
            isLoading: false,
            surplusProducts: [],
            currentAdIdx: 0,
        }
    },
    methods: {
        updateIndex(idx) {
            console.log(idx, this.currentAdIdx)
            const condition = idx >= 0 && idx < this.surplusProducts.length
            if (condition) {
                return (this.currentAdIdx = idx)
            }
            if (idx < 0) {
                return (this.currentAdIdx = this.surplusProducts.length - 1)
            }
            if (idx >= this.surplusProducts.length) {
                return (this.currentAdIdx = 0)
            }
        },
        async fetchAds() {
            const res = await request.get("/surplus/ads")
            const data = res.data
            this.surplusProducts = data.result
        },
    },
    async mounted() {
        console.log("current ad index", this.currentAdIdx)
        this.isLoading = true
        try {
            await this.fetchAds()
        } catch (err) {
            console.log("error in fetching ads", err)
        }
        this.isLoading = false
    },
}
</script>

<style></style>
