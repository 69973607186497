<template>
    <div>
        <Loader :isLoading="isLoading && showLoader" />
        <div class="profile-edit-left">
            <div class="mb-3"><i class="fa fa-search"></i> SEARCH</div>
            <form @submit.prevent>
                <!-- <div class="search-input-container">
                    <input
                        type="text"
                        :class="['search-input', { active: !!select_pincode }]"
                        v-model="select_pincode"
                        placeholder="Enter your pincode.."
                        maxlength="6"
                    />
                    <button
                        class="filter-btn"
                        @click="filterProducts"
                        style="box-shadow: none;"
                        v-if="filterType === filterTypes.FILTER"
                    >
                        Find
                    </button>
                </div> -->
                <div class="search-input-container">
                    <!-- <input
                        type="text"
                        :class="['search-input', { active: !!select_keyword }]"
                        v-model="select_keyword"
                        placeholder="Enter keyword.."
                    /> -->
                    <KeywordInput
                        v-model="select_keyword"
                        :insideFilterSidebar="true"
                        :active="!!select_keyword"
                    />
                    <button
                        class="filter-btn"
                        @click="filterProducts"
                        style="box-shadow: none;"
                        v-if="filterType === filterTypes.FILTER"
                    >
                        Search
                    </button>
                </div>
                <hr />
                <select
                    v-model="select_category"
                    :class="['select-dropdown-filter', { active: !!select_category }]"
                    @change="filterProducts"
                >
                    <option disabled value="">Choose Category..</option>
                    <option value="">All Categories</option>
                    <option
                        v-for="category in categories"
                        :key="category.id"
                        :value="category.id"
                        >{{ category.name }}</option
                    >
                </select>
                <select
                    v-model="select_state"
                    @change="filterProducts"
                    :class="['select-dropdown-filter', { active: !!select_state }]"
                >
                    <option disabled value="">Select State</option>
                    <option value="">All States</option>
                    <option
                        v-for="(state, index) in all_state"
                        :key="index"
                        :value="state.state_code"
                        >{{ state.state_name }}</option
                    >
                </select>
                <select
                    v-model="select_city"
                    v-if="select_state"
                    :class="['select-dropdown-filter', { active: !!select_city }]"
                >
                    <option disabled value="">Select City</option>
                    <option value="">All Cities</option>
                    <option
                        v-for="(city, index) in all_city"
                        :key="index"
                        :value="city.city_code"
                        >{{ city.city_name }}</option
                    >
                </select>

                <!--<div class="range-wrap">-->
                <!--   <div class="range-value" id="rangeV"></div>-->
                <!--   <input-->
                <!--      id="range"-->
                <!--      type="range"-->
                <!--      min="0"-->
                <!--      max="100"-->
                <!--      value="0"-->
                <!--      step="1"-->
                <!--      />-->
                <!--</div>-->
                <!--<button type="submit" @click="search">SEARCH NOW</button>-->
            </form>
            <div class="clear-filter-btn-container" v-if="filterType === filterTypes.REDIRECT">
                <button class="filter-btn " @click="redirectSearch" style="box-shadow: none;">
                    Search
                </button>
            </div>
            <div class="clear-filter-btn-container" v-else>
                <button class="filter-btn" @click="clearFilters" style="box-shadow: none;">
                    Clear All Filters
                </button>
            </div>
        </div>
        <div class="profile-edit-left">
            <Advertisement />
        </div>
        <div class="profile-edit-left">
            <h2><i class="fa fa-bars"></i> CATEGORIES</h2>
            <ul
                :class="['left-profile-link', { active: category.id === select_category }]"
                v-for="category in categories"
                :key="category.id"
            >
                <li @click="loadCategory(category)" class="category-filter-list-item">
                    <div class="row">
                        <div class="col-sm-3">
                            <ImageItem
                                class="categorie_image"
                                v-bind:style="{
                                    background: category.color,
                                }"
                                :src="imgsrc + category.img"
                                style="width: 45px;height: 45px;margin:0px;"
                            />
                        </div>
                        <div class="col-sm-9" style="padding: 10px;">
                            <a href="javascript:void()"
                                >{{ category.name
                                }}<span style="padding:0px;">{{ category.surplus_count }}</span></a
                            >
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <!--<div class="profile-edit-left">-->
        <!--   <h2><i class="fa fa-bars"></i> SUBCATEGORIES</h2>-->
        <!--   <ul-->
        <!--      class="left-profile-link"-->
        <!--      v-for="(user, index) in subbcategory"-->
        <!--      :key="index"-->
        <!--      >-->
        <!--      <li>-->
        <!--         <div class="row">-->
        <!--            <div class="col-sm-3">-->
        <!--               <img-->
        <!--                  class="categorie_image"-->
        <!--                  v-bind:style="{ background: user.color }"-->
        <!--                  :src="imgsrc + user.img"-->
        <!--                  style="width: 45px;height: 45px;margin:0px;"-->
        <!--                  />-->
        <!--            </div>-->
        <!--            <div-->
        <!--               class="col-sm-9"-->
        <!--               style="background-color:#fafafa;padding: 10px;"-->
        <!--               >-->
        <!--               <a href="#"-->
        <!--                  >{{ user.name }}<span style="padding:0px;">3</span></a-->
        <!--                  >-->
        <!--            </div>-->
        <!--         </div>-->
        <!--      </li>-->

        <!--   </ul>-->
        <!--</div>-->
        <div class="profile-edit-left">
            <h2><i class="fa fa-share-alt"></i> FOLLOW US ON</h2>
            <ul class="share-icon-container">
                <li class="share-icon">
                    <a href="https://www.facebook.com/scholenberg.3" target="_blank"
                        ><i class="fa fa-facebook-f" style="width: 46px;"></i
                    ></a>
                </li>
                <li class="share-icon">
                    <a href="https://twitter.com/Scholenberg1" target="_blank"
                        ><i class="fa fa-twitter" style="width: 46px;"></i
                    ></a>
                </li>
                <li class="share-icon">
                    <a
                        href="https://www.linkedin.com/in/scholenberg-consulting-2567181b0/"
                        target="_blank"
                        ><i class="fa fa-linkedin" style="width: 46px;"></i
                    ></a>
                </li>
                <li class="share-icon">
                    <a href="https://www.instagram.com/scholenberg123/" target="_blank"
                        ><i class="fa fa-instagram" style="width: 46px;"></i
                    ></a>
                </li>
                <li class="share-icon">
                    <a
                        href="https://www.youtube.com/channel/UCT1b9Xy36suK8GNxe2CrPbQ"
                        target="_blank"
                        ><i class="fa fa-youtube" style="width: 46px;"></i
                    ></a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Loader from "../Loader.vue"
import KeywordInput from "./KeywordInput.vue"
import axios from "axios"
import request from "../../apis/request"
import scrollTop from "./scrollTop"
import ImageItem from "./ImageItem"
import Advertisement from "./Advertisement.vue"

export default {
    components: {
        Loader,
        KeywordInput,
        Advertisement,
        ImageItem,
    },
    model: {
        prop: "surplusProducts",
        event: "updateSurplusProducts",
    },
    emits: ["updateSurplusProducts", "updatePage", "updateTotalResults", "filterProcessing"],
    props: {
        surplusProducts: {
            type: Array,
            default: () => [],
        },
        page: {
            type: Number,
            default: 1,
        },
        perPageLimit: {
            type: Number,
            default: 1,
        },
        filterType: {
            type: String,
            default: "filter",
        },
        redirectUrl: {
            type: String,
            default: "/search",
        },
        myProducts: {
            // used to determine whether to load products created by the authenticated user
            type: Boolean,
            default: false,
        },
        showLoader: {
            type: Boolean,
            default: true,
        },
        noScrollTop: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            filterTypes: {
                REDIRECT: "redirect",
                FILTER: "filter",
            },
            select_keyword: "",
            select_state: "",
            select_city: "",
            select_pincode: "",
            select_category: "",

            categories: null,
            all_city: null,
            all_state: null,
            isLoading: false,
            // imgsrc: "http://103.212.120.205/~dev/public/admin_profile/",
            imgsrc: axios.defaults.uploadURL,
        }
    },
    watch: {
        async page(e) {
            await this.filterProducts(false)
        },
        async isLoading(e) {
            this.$emit("filterProcessing", e)
        },
        async select_state(e) {
            this.select_city = ""
            await this.fetchCities()
        },

        async select_city(e) {
            await this.filterProducts()
        },
    },
    async mounted() {
        if (!this.noScrollTop) scrollTop()
        this.isLoading = true
        try {
            await this.loadFilterOptions()
            if (this.filterType === this.filterTypes.FILTER) {
                this.loadFiltersFromUrl()
                await this.filterProducts(false)
            }
        } catch (err) {
            console.log(err)
        }
        this.isLoading = false
    },
    methods: {
        async loadFilterOptions() {
            try {
                const res = await request.get(
                    "/surplus/filter-options?all=true&myProducts=" + this.myProducts
                )
                const filterOptions = res.data.filterOptions
                this.categories = filterOptions.categoryOptions
                // this.all_city = filterOptions.cityOptions
                this.all_state = filterOptions.stateOptions
            } catch (err) {
                console.log("error in loading filter options")
            }
        },
        async fetchCities() {
            const res = await request.get("/city/" + this.select_state)
            this.all_city = res.data
            console.log("all citites", res.data)
        },

        addFiltersToUrl(url) {
            let query = `&page=${this.page}`
            if (this.select_pincode) {
                query += `&pincode=${this.select_pincode}`
            }
            if (this.select_category) {
                query += `&category=${this.select_category}`
            }
            if (this.select_city) {
                query += `&city=${this.select_city}`
            }
            if (this.select_state) {
                query += `&state=${this.select_state}`
            }
            if (this.select_keyword) {
                query += `&keyword=${this.select_keyword}`
            }
            if (query) {
                if (!url.includes("?")) {
                    url += `?${query.slice(1)}`
                } else {
                    url += query
                }
            }
            return url
        },
        loadFiltersFromUrl() {
            const { pincode, category, city, state, keyword, page } = this.$route.query
            if (pincode) {
                this.select_pincode = pincode
            }
            if (category) {
                this.select_category = Number(category)
            }
            if (city) {
                this.select_city = Number(city)
            }
            if (state) {
                this.select_state = Number(state)
            }
            if (keyword) {
                this.select_keyword = keyword
            }
            if (page) {
                this.$emit("updatePage", Number(page))
            }
        },
        async filterProducts(forceFirstPage = true, countResults = false) {
            if (this.filterType === this.filterTypes.REDIRECT) {
                return
            }
            this.isLoading = true
            let filterUrl = "/surplus/filter"
            if (forceFirstPage) {
                this.$emit("updatePage", 1)
            }
            filterUrl = this.addFiltersToUrl(filterUrl)
            // if (forceFirstPage) {
            //     return this.$router.push(filterUrl)
            // }
            if (countResults) {
                filterUrl = "/surplus/filter?count=true"
            }
            window.history.replaceState(
                { data: "anything" },
                null,
                this.addFiltersToUrl(window.location.href.split("?")[0])
            )
            try {
                const res = await request.post(filterUrl, {
                    limit: this.perPageLimit,
                    myProducts: this.myProducts,
                })
                const { result, total } = res.data
                if (countResults) {
                    this.$emit("updateTotalResults", result)
                } else {
                    // this.totalResults = total
                    // this.surplusProducts = result
                    this.$emit("updateSurplusProducts", result)
                    this.$emit("updateTotalResults", total)
                    this.$emit("updateData", res.data)
                    // console.log(this.totalResults)
                }
            } catch (err) {
                console.log("Error in filtering products")
            }
            if (!this.noScrollTop) scrollTop()

            this.isLoading = false
        },
        async loadCategory(category) {
            this.select_category = category.id
            if (this.filterType === this.filterTypes.FILTER) {
                window.scroll(0, 200)
                this.select_pincode = ""
                this.select_city = ""
                this.select_state = ""
                this.select_keyword = ""
                await this.filterProducts()
            } else {
                this.$router.push({
                    name: "search",
                    query: { page: 1, category: this.select_category },
                })
            }
        },
        async clearFilters() {
            this.select_category = ""
            this.select_pincode = ""
            this.select_city = ""
            this.select_state = ""
            this.select_keyword = ""
            await this.filterProducts()
        },
        redirectSearch() {
            const category = this.select_category
            const pincode = this.select_pincode
            const city = this.select_city
            const state = this.select_state
            const keyword = this.select_keyword
            const query = {
                category,
                pincode,
                state,
                city,
                keyword,
            }
            if (!this.noScrollTop) scrollTop()

            return this.$router.push({ name: "product-listing", query })
        },
    },
    beforeDestroy() {
        this.isLoading = true
        console.log("filter component unmounting")
    },
}
</script>

<style lang="scss" scoped>
$btn-bg-color: rgb(176, 24, 24);
$input-bg: rgb(245, 245, 245);
$active-input-bg: rgb(250, 255, 217);
$shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
$font-size: 0.8em;

.share-icon-container {
    list-style-type: none;
    display: flex;
    margin-left: 0;
    justify-content: flex-start;
    margin-top: 15px;
    padding: 0;

    .share-icon {
        // cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 13px 5px;
        border: 1px solid #bbb;
        text-align: center;
        background: white;
        transition: 0.3s;

        &:hover {
            background: rgb(255, 221, 227);
        }
    }
}
.search-input-container {
    display: flex;
    height: 45px;
    margin: 0;
    align-items: center;
    font-size: $font-size;
    margin: 10px 0;
    // border-radius: 5px;
    // overflow: hidden;
    box-shadow: $shadow;

    .search-input {
        flex: 1;
        height: 100%;
        width: 100%;
        padding: 5px;
        border: none;
        background-color: $input-bg;
        outline: none;
        padding: 0 15px;
        color: #444;
        transition: 0.3s;

        &.active {
            background-color: $active-input-bg;
        }
    }
}
.filter-btn {
    outline: none;
    width: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    height: 100%;
    background-color: $btn-bg-color;
    border-radius: 0;
    color: white;
    border: 1px solid;
    font-size: 0.8em;
    align-items: center;

    &:hover {
        background: lighten($btn-bg-color, 5%);
    }
}

.select-dropdown-filter {
    font-size: $font-size;
    display: block;
    width: 100%;
    margin: 10px 0;
    padding: 10px 8px;
    outline: none;
    border: none;
    font-family: Nunito;
    background-color: $input-bg;
    box-shadow: $shadow;
    transition: 0.3s;

    &.active {
        background-color: $active-input-bg;
    }
}
.clear-filter-btn-container {
    display: flex;
    font-size: $font-size;

    .filter-btn {
        margin: 0;
        flex: 1;
        border-radius: 5px;
        padding: 8px;
        background-color: $btn-bg-color;
        outline: none;

        &:hover {
            background: lighten($btn-bg-color, 5%);
        }
    }
}
.category-filter-list-item {
    font-size: $font-size;
}
</style>
