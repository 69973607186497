<template>
    <div class="ad-item-container" @click="handleClick">
        <div class="image-container">
            <ImageItem :src="imgsrc + item.image" :alt="item.title" class="img-fluid" />
        </div>
        <div class="title">{{ item.title }}</div>
    </div>
</template>

<script>
import axios from "axios"
import ImageItem from "./ImageItem.vue"
export default {
    props: {
        item: {
            type: Object,
            default: () => null,
        },
    },
    data() {
        return {
            imgsrc: axios.defaults.uploadURL,
        }
    },
    components: {
        ImageItem,
    },
    methods: {
        handleClick() {
            const href = this.$router.resolve({
                name: "single-product",
                params: { id: this.item.id },
            }).href
            window.location.href = href
        },
    },
}
</script>

<style lang="scss" scoped>
.ad-item-container {
    display: flex;
    cursor: pointer;
    transition: 0.3s;
    padding: 5px 10px;

    &:hover {
        background: rgb(224, 231, 235);
    }

    .image-container {
        flex: 1;
    }
    .title {
        flex: 1;
        display: flex;
        margin-left: 10px;
        align-items: center;
        font-size: 0.8em;
    }
}
</style>
